<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <!-- <el-select v-model="query.level" filterable clearable placeholder="经销商类型" class="filter-item" style="width: 150px" @change="toQuery">
        <el-option v-for="item in dictMap.distributor_level" :key="item.id" :label="item.label" :value="item.id" />
      </el-select>-->
      <!-- <quick-select v-model="query.level" url="api/distributorLeve/list" placeholder="经销商类型" style="width: 150px" @change="toQuery" clearable filterable  class="filter-item" /> -->

      <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 200px;" />
      <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 200px;" />
      <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 200px;" />
      <el-input v-model="query.name" clearable placeholder="输入商品名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <!-- 表格 -->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="code" label="商品编码" min-width="130" />
      <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
      <el-table-column prop="name" label="商品名称" min-width="240" />
      <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
      <el-table-column prop="brandName" label="品牌" width="160" />
      <el-table-column prop="categoryName" label="类目" width="160" />
      <el-table-column prop="seriesName" label="系列" width="160" />
      <el-table-column label="统一零售价" width="120" :formatter="v=>{return '￥'+(v.retailPrice/100).toFixed(2)}" align="right" />
      <el-table-column width="90" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.goodsPrice" size="mini" type="text" @click="detail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import request from "@/utils/request";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData, initDict],
  created() {
    this.getDictMap("enterprise_type,distributor_level").then((_) => {
      this.init();
    });
  },
  data() {
    return {
      query: {
        brandId: null,
        seriesId: null,
        categoryId: null,
        name: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/distributorGoodsPrice/all";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "name,asc" },
        this.query
      );
      return true;
    },
    detail(data) {
      this.$refs.form.resetForm(data);
    },
  },
};
</script>